/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useCallback, useEffect } from 'react';

import ReactSelect from 'react-select';
import { useField, FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';
import { Error } from '../styles';
import { useLanguage } from '../../../hooks/Language';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  options: Array<IOptions>,
  placeholder?: string;
  isMulti?: boolean
  theme?: string;
  mandatory?: boolean;
}


const SelectSimple: React.FC<Props> = ({
  name,
  label,
  options,
  formRef,
  theme = 'light',
  placeholder = 'Selecionar',
  mandatory = false,
  isMulti = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCallback = (value) => { },
  ...rest
}) => {
  const selectRef = useRef(null);
  const { translate } = useLanguage();
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(defaultValue || null);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  useEffect(() => {
    setValue(
      defaultValue &&
      options?.find(
        option => option.value.toString() === defaultValue.toString(),
      ),
    );
  }, [defaultValue, options]);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
    setIsFilled(!!value);
  }, [value]);

  //* * Usefull function to call external functions */
  /* useEffect(() => {
    if (value && value.value) {
      setCallback(value.value, formRef);
    }
  }, [value, setCallback]); */

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        if (!ref.state.value) {
          return '';
        }

        if (!ref.state.value.value) {
          return ref.state.value;
        }
        return ref.state.value.value;
      },

      setValue: (ref, valueUF) => {
        const validValue = options?.find(option => {
          return option.value === valueUF;
        });

        if (validValue) {
          ref.state.value = validValue;
          setValue(validValue);
        }
      },
    });
  }, [fieldName, registerField, isMulti]);

  const activeCallback = (selectedValue) => {
    if (selectedValue && selectedValue.value) {
      setCallback(selectedValue.value);
    }

  }

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <span style={{ color: '#333' }}>{translate(label)} {mandatory ? <span className='mandatory'>*</span> : <></>}</span>
      <ReactSelect
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        options={options}
        placeholder={placeholder}
        onChange={selectedValue => {
          setValue(selectedValue);

          activeCallback(selectedValue);
        }}
        value={value}
        ref={selectRef}
        styles={{ placeholder: (styles) => ({ ...styles, color: theme === 'light' ? '#333' : '#fff' }) }}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default SelectSimple;
