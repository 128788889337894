import React from 'react';
import { useState } from "react";
import { FaChevronDown, FaChevronLeft } from "react-icons/fa";
import RenderLink from "../RenderLink";

interface IMenu {
    [key: string]: any;
    title?: string;
    type?: string;
    active?: string;
    submenu?: Record<string, any>[];
}

interface IProps {
    content: IMenu;
    closeMenu: Function;
    changePath: Function;
    indexNumber: number;
    currentPath: Array<number>;
    position: number;
}

const MenuItem: React.FC<IProps> = ({ indexNumber, content, closeMenu, changePath, currentPath, position }) => {

    const [activeMenu, setActiveMenu] = useState(true);
    const { submenu, active } = content;

    const applyCloseMenu = () => {
        setActiveMenu(false);
        closeMenu()
    }





    if (active !== 'yes') {
        return <></>
    }



    console.log(currentPath?.length, position + 1)

    return <>{currentPath?.length > (position + 1) ? <></> : currentPath?.length === (position + 1) && currentPath?.[position] === indexNumber ? <nav className="fadeIn cursor" style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }} onClick={() => changePath('close')}><FaChevronLeft style={{ fontSize: '16px', marginRight: '10px' }} /> <p>{position === 0 ? 'MENU' : content?.title}</p></nav> : currentPath?.length === (position + 1) ? <></> : <><RenderLink active={activeMenu} activeSubmenu={() => changePath('', indexNumber)} content={content} closeMenu={closeMenu} /> </>}
        {currentPath?.length > (position) && currentPath?.[position] === indexNumber ? <div className='column fadeIn gap-xs' style={{ paddingLeft: '15px' }}>
            {submenu?.map((cat, index) => {

                return currentPath?.length > (position) && currentPath?.[position] === indexNumber ? <MenuItem indexNumber={index} changePath={changePath} currentPath={currentPath} position={position + 1} content={cat} closeMenu={closeMenu} /> : <></>

            })}
        </div> : <></>}
    </>

}

export default MenuItem;