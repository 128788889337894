import styled from "styled-components";

export const Rodape = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
gap: 30px;
width: 100%;
margin-top: 50px;
position: relative ;
//padding-top: 200px;
padding-bottom: 50px;
//margin-top: 350px;
max-width: 100% !important;

background: url("/apoio/background.png") no-repeat top center;
background-size: cover;
z-index: 100;

@media (max-width: 750px) {
     width: 100%;
     max-width: 100%;
     padding-top: 0px ;
     margin-top: 0px;
  }
 
.leftElement{
    width: 100%; 
    align-self: flex-start; 
    max-width: 1230px;
    margin-left: -30px; 
    border-radius: 0px 70px 70px 0px;
    display: flex ;
    justify-content: flex-end !important;
    flex-direction: row;
    position: absolute ;
    left: 0px;
    bottom: 0px ;
    z-index: 15;

    .leftElement2{
        width: 100%; 
    align-self: flex-start; 
    max-width: 50%;
    min-height: 105px;
    margin: 0px;
    margin-left: -30%; 
    border-radius: 0px ;
    display: flex ;
    justify-content: flex-end !important;
    flex-direction: row;
    position: absolute ;
    left: 0px;
    bottom: 0px ;
    z-index: 15;  
    }
}

.book{
    position: absolute ;
    left: 10%;
    bottom: -140px;
    width: 40%;
}

.bookLine{
    margin-top: -330px;
    
}

img{
            width: 100%;
        }

        .content1{
            width: 55%;
    
    max-width: 660px; 

    @media (max-width: 750px) {
     width: 100% !important;
     max-width: 100% !important;
     min-width: 100% !important;
  }

    img{
        z-index: 100;
    }
        }

.content2{
    width: 45%;
    
    max-width: 540px;
}

.content2{
    gap: 15px;
    display: flex;
align-items: flex-start;
justify-content: flex-end;
flex-direction: column;
}

a{
    color: #fff;
    cursor: pointer;
}

h2,p,.thumbsVideosSlider{
    color: #fff;
    padding: 10px 30px;
}

 
p{
    line-height: 28px;
}

.thumbsVideosSlider{
    width: 100% ;
    overflow-y: auto;
    display: flex ;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 5px;

    .thumbsVideos{
        width: 100% ;
        max-width: 200px;
        min-width: 200px;
       background:#333;
    }
}

`;