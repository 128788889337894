

import Input from '../../../../../../pageAdmin/core/components/Forms/Input';


import { hash, recaptchaKey, urlWebsite, websitePath } from '../../../../../../config';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useToast } from '../../../../../../hooks/Toast';


import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import api from '../../../../../../services/api';
import { loadFromApiJson } from '../../../../../../services/loadFromApiJson';
import SelectSimple from '../../../../../../pageAdmin/core/components/Forms/SelectSimple';
import TextareaInput from '../../../../../../pageAdmin/core/components/Forms/TextareaInput';
interface IProps {
    hashForm?: String;

}

interface ICaptcha {
    getValue(): string;
}

interface IChecker {
    value: string;
    options: Record<string, any>[];
}

const OptionChecker: React.FC<IChecker> = ({ value, options }) => {

    if (!value) {
        return <></>;
    }

    const item = options.find(i => i?.title === value);



    if (item?.options?.length > 0) {

        const optionsValues = item?.options?.reduce((prev, item: Record<string, any>) => {

            prev.push({ label: item?.title, value: item?.title });

            return prev;


        }, []);

        return <SelectSimple name="subarea" label={item?.subtitle} options={optionsValues} />

    }

    return <></>;



}

function getQueryParams(url: string) {
    const urlObj = new URL(url);
    const hashParams = new URLSearchParams(urlObj.hash.slice(urlObj.hash.indexOf('?')));

    const areaValue = hashParams.get('area') || '';
    const subareaValue = hashParams.get('subarea') || '';

    return { areaValue, subareaValue };
}

function useStateHook() {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [area, setArea] = useState('');
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        const { areaValue, subareaValue } = getQueryParams(window.location.href);

        if (areaValue) {
            setArea(areaValue)
        }

    }, [window?.location?.hash])

    const changeArea = (value: string) => {
        setArea(value);
    }

    const changeFinished = (value: boolean) => {
        setFinished(value);
    }


    return { area, changeArea, finished, changeFinished };
}

const Contact: React.FC<IProps> = ({ hashForm = 'default' }) => {

    const [formInfo, setFormInfo] = useState<Record<string, any>>({});
    const { configuration } = useConfiguration();
    const { addLoading, removeLoading } = useLoading();
    const { areaValue, subareaValue } = getQueryParams(window.location.href);

    const { area, changeArea, finished, changeFinished } = useStateHook();

    const recaptchaRef = useRef(null);
    const options = formInfo?.email ? formInfo?.email?.reduce((prev: Array<Record<string, any>>, item: Record<string, any>) => {
        prev.push({ label: item.title, value: item.title });
        return prev;
    }, [])?.sort((a: Record<string, any>, b: Record<string, any>) => a?.label > b?.label ? 1 : a?.label < b?.label ? -1 : 0) : [];

    const { addToast } = useToast();

    const getCaptchaValue = (elem: ICaptcha): string => {
        return elem.getValue();
    };


    const sendForm = async (data: Record<string, any>, formContent: Record<string, any>[]) => {
        addLoading({ title: 'Enviando...', key: 'loading' });

        const captchaValue =
            getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
            false;

        if (!captchaValue) {
            addToast({ title: 'Clique em Não sou um robô', type: 'error' })

            removeLoading('loading');
            return;
        }


        if (!data?.name) {
            addToast({ title: 'Preencha o campo Nome', type: 'error' })
            removeLoading('loading');
            return;
        }
        if (!data?.phone) {
            addToast({ title: 'Preencha o campo Telefone', type: 'error' })
            removeLoading('loading');
            return;
        }


        if (!data?.email) {
            addToast({ title: 'Preencha o campo e-mail', type: 'error' })
            removeLoading('loading');
            return;
        }

        if (!data?.delivery) {
            addToast({ title: 'Selecione uma área', type: 'error' })
            removeLoading('loading');
            return;
        }

        const item = formContent?.find(i => i?.title === data?.delivery);
        if (!item) {
            addToast({ title: 'Erro ao identificar área', type: 'error' })
            removeLoading('loading');
            return;
        }

        if (item?.options?.length > 0) {
            if (!data?.subarea) {
                addToast({ title: `Selecione a categoria: ${item?.subtitle}`, type: 'error' })
                removeLoading('loading');
                return;
            }


        }



        try {
            await api.post(`/send-mail-contact/leite-ccgl/${formInfo?.url?.toString()}`, { ...data, website: websitePath, imagePath: 'https://grupoccgl.encontrodigital.com.br' })

            addToast({ title: 'Enviado com sucesso' });
            changeFinished(true);
            removeLoading('loading');
        }
        catch (err) {

            addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao submeter', type: 'error' })
            removeLoading('loading')
        }
    }


    const loadForm = async () => {
        if (configuration) {

            const response: Array<Record<string, any>> = await loadFromApiJson({ hash: hash, project_id: 'leite-ccgl' || '', table: 'MailContactOption' });

            if (response?.[0]) {

                const content = response?.find(item => item?.url === hashForm);

                if (content) {
                    setFormInfo(content);
                }


            }
        }
    }

    const item = formInfo?.email?.find((i: Record<string, any>) => i?.title === area);



    const optionsValues = item?.options?.reduce((prev, item: Record<string, any>) => {

        prev.push({ label: item?.title, value: item?.title });

        return prev;


    }, []);


    useEffect(() => {
        loadForm();
    }, [])

    useEffect(() => {
        if (item?.title === 'Consumidor') {

            window.location.href = 'https://app.ccgl.com.br/site/falecomaccgl';

        }
    }, [item])



    if (finished) {
        return <div className='full-width row-to-column  gap-bg'  >

            <div className='column gap text-center'>
                <FaCheckCircle style={{ fontSize: '120px', color: 'rgb(0,255,0)' }} />
                <h2 className='hugeTitle2 colorWhite max-800' style={{ fontSize: '32px', lineHeight: '36px' }}>
                    Mensagem enviada<br /> com sucesso
                </h2>
                <button className='whiteButton' style={{ marginTop: '15px' }} onClick={() => changeFinished(false)} >Enviar outra mensagem</button>
            </div>


        </div>
    }



    return formInfo ? <div className='full-width  column  '>

        <div>
            <h2 className='hugeTitle2 colorWhite max-800' style={{ textAlign: 'left' }}>
                {formInfo?.title}
            </h2>

            <p className='whiteColor' dangerouslySetInnerHTML={{ __html: formInfo?.description || '<p></p>' }} />
        </div>

        <div className='row gap-sm max-400' style={{ marginTop: '10px' }}>
            <Form className='column ' onSubmit={(data) => sendForm(data, formInfo?.email)} initialData={{ delivery: area || options?.[0]?.label, subarea: subareaValue }}>
                <SelectSimple setCallback={(value: string) => changeArea(value)} options={options} name={'delivery'} label="Área" />
                {optionsValues?.length ? <SelectSimple options={optionsValues} name={'subarea'} label={item?.subtitle} /> : <></>}
                <br />
                <Input placeholder='Nome' name='name' />
                <Input placeholder='E-mail' name='email' />
                <Input placeholder='Telefone com DDD' name='phone' />

                <TextareaInput placeholder='Mensagem' name='message' />
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
                <button className='whiteButton' style={{ marginTop: '15px' }} >Enviar</button>
            </Form>
        </div>
    </div> : <></>


}

export default Contact;