import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash, urlWebsite } from '../../../../config';
import IframePlayer from '../../../../components/IframePlayer';
import { ContainerVideoListDisplay } from './styles';
import { Link } from 'react-router-dom';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const ReceitasCapa: React.FC<IParam> = () => {
    const [conteudos, setConteudos] = useState<Array<Record<string, any>>>([


    ]);

    const load = async () => {

        const items = await api.get(`/list-cientific-library`);

        setConteudos(items?.data?.rows);




    }

    useEffect(() => {
        load();
    }, [])


    return <ContainerVideoListDisplay tabIndex={0} className='max-1200 row-to-column'>

        <div className='content1' >
            <IframePlayer id={'https://iframe.mediadelivery.net/embed/214843/107158f8-098c-457a-acd3-f96d14ae3c9a'} />

        </div>
        <div className='content2'  >
            <h2 className='titleH2'>RECEITA</h2>
            <p>
                Esse é o melhor cuscuz de tapioca da vida! E com ingredientes que fazem a diferença!<br />
                <br />
                <strong>Ingredientes:</strong><br />
                150g de tapioca granulada<br />
                3 colheres de sopa de açúcar<br />
                4 colheres de sopa de leite em pó integral CCGL diluídos em 300 ml de água<br />
                100 ml de leite de coco<br />
                2 colheres de sopa de coco ralado<br />
                Leite condensado CCGL para servir por cima.<br />
                <br />
                Sigam a @ccgloficial para mais dicas como essa.
            </p>
            {/*   <div className='thumbsVideosSlider'>
                {conteudos?.map((conteudo, index) => {
                    return index > 0 ? <Link onClick={() => window?.scrollTo(0, 0)} to={`/conteudos/acessar/${conteudo?.url}`} key={`receitas-capa-${conteudo?._id}`} className='thumbsVideos'>
                        <div className='imgDisplay' style={{ background: `url("${urlWebsite}/${conteudo?.image}")` }} />
                    </Link> : <></>
                })}
         
            </div>
   */}
        </div>

    </ContainerVideoListDisplay >


}

export default ReceitasCapa;