import api from "../../../services/api";

export const loadSimilar = async (poster, callback) => {


    const response = await api.get(`/list-cientific-library`, {
        params: {
            where: { url: { "$ne": poster?.url }, project_id: poster?.project_id },
            order: { orderBy: 'createdAt', orderDirection: 'desc' },
        }
    });

    callback(response?.data?.rows)


}