import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';

import { UpdateCoursesModule } from '../Courses';
import { FiSpeaker, FiStar } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const LeadCapturePage: React.FC = () => {
  const endpoint = '/lead-capture-page';
  const { socket } = useSocket();
  const title = 'Páginas';


  const formSchema = {

    name: {
      model: 'input',
      name: 'name',
      label: 'Nome',
      defaultValue: '',
    },
    email: {
      model: 'input',
      name: 'email',
      label: 'E-mail',
      defaultValue: '',
    },
    phone: {
      model: 'input',
      name: 'phone',
      label: 'Telefone',
      defaultValue: '',
    },

  };

  const formSchemaUpdate = {
    ...formSchema,


  };




  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },

    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },

    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'E-mail' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };




  const config: ISearchComponent = {
    idKey: `${endpoint}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: `${endpoint}`,
    title: 'Páginas de captura',
    endpoint: '/lead-capture-page',
    schema: schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [



      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),


      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default LeadCapturePage;
