import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import RodapeLP from '../Home/modules/RodapeLP';
import { scrollToID } from '../../utils/scrollTo';
import { Link } from 'react-router-dom';

const FooterLayout: React.FC = ({ children }) => {
  return (<><div style={{ zIndex: -1 }}>{/* 
  <Particles
      params={{
        particles: {
          lineLinked: {
            enable: false,
            distance: 250
          },
          move: {
            speed: 15,
            direction: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? 'bottom' : 'top-right',
            collisions: false,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },


          },
          opacity: {
            value: 0.4,
            random: false,
            animation: {
              enable: false
            }

          },
          shape: {
            type: 'circle',
            polygon: {

              nb_sides: 6,

            },

          },

          color: { value: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? '#f25930' : 'rgb(171, 230, 255)' },

          number: {
            density: {
              enable: true,
              value_area: 2400,
            },
            value: 60,
          },
          size: {
            value: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? 10 : 2,
            random: true,
          },
        },
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
      }}
    />
    */}
    {window?.location?.pathname !== '/' ? <RodapeLP /> : <></>}


  </div >

    <div className="full-width " style={{ background: '#efefef', paddingTop: '10px' }}>

      <div className='full-width row-to-column  gap ' style={{ padding: '15px 30px', justifyContent: 'center' }}>


        <div className="full-width " style={{ background: '#efefef', paddingTop: '10px' }}>

          <div className='full-width row-to-column  gap ' style={{ padding: '15px 30px', justifyContent: 'center' }}>



            <div className="full-width row row-to-column max-1200 gap-sm" style={{ alignItems: 'flex-start' }}>

              <div className="column-start gap-sm  max-300  pad text-left" >
                <h2 style={{ fontSize: '16px', lineHeight: '20px', color: '#333' }}>A CCGL</h2>
                {/*
      <a tabIndex={0} href='https://grupoccgl.com.br/app/grupo-ccgl/paginas/quem-somos' target='_BLANK' className='row row-start gap-sm ' >
        <p className="colorBlack"> Quem somos</p>
      </a>

      <Link tabIndex={0} to='https://grupoccgl.com.br/#cooperativas' onClick={() => scrollToID('cooperativas')} className='row-start  gap-xs column-start-mobile ' >
        <p className="colorBlack">Associadas</p>
      </Link>
*/}

                <a tabIndex={0} href='https://grupoccgl.com.br' target='_BLANK' className='row row-start gap-sm ' >
                  <p className="colorBlack"> Quem somos</p>
                </a>

                <Link tabIndex={0} to='https://grupoccgl.com.br' onClick={() => scrollToID('cooperativas')} className='row-start  gap-xs column-start-mobile ' >
                  <p className="colorBlack">Associadas</p>
                </Link>




                <a tabIndex={0} href='https://loja.ccgl.com.br/' target='_BLANK' className='row   gap-xs column-start column-start-mobile ' >
                  <p className="colorBlack text-left"> Loja virtual</p>
                </a>
              </div>




              <div className="column-start gap-sm  max-300   pad" >
                <h2 style={{ fontSize: '16px', lineHeight: '20px', color: '#333' }}>Segurança e privacidade</h2>
                <a tabIndex={0} href='https://app.ccgl.com.br/utils/politicas/site/politica.html' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Política de Privacidade e Termos de uso</p>
                </a>

                <a tabIndex={0} href='https://app.ccgl.com.br/utils/politicas/politica_de_cookies.html' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Política de Cookies</p>
                </a>
                <a tabIndex={0} href='https://app.ccgl.com.br/utils/politicas/codigo_de_conduta.pdf' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Código de conduta</p>
                </a>

                <a tabIndex={0} href='https://app.ccgl.com.br/utils/politicas/politica_de_qualidade.html' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Política da Qualidade e Segurança dos alimentos</p>
                </a>
              </div>

              <div className="column-start gap-sm  max-300   pad" >
                <h2 style={{ fontSize: '16px', lineHeight: '20px', color: '#333' }}>Áreas e Unidades</h2>
                <a tabIndex={0} href='https://leite-ccgl.encontrodigital.com.br' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Lácteos</p>
                </a>

                <a tabIndex={0} href='https://www.termasa.com.br/servicos.html' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Logística TERMASA</p>
                </a>
                <a tabIndex={0} href='http://www.tergrasa.com.br/index.html' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Logística TERGRASA</p>
                </a>
                {/*
      <a tabIndex={0} href='https://grupoccgl.com.br/app/grupo-ccgl/paginas/analise-de-solo' target='_BLANK' className='row row-start gap-xs ' >
        <p className="colorBlack"> Laboratório de Análise de Solos</p>
      </a>

      <a tabIndex={0} href='https://grupoccgl.com.br/app/grupo-ccgl/paginas/analise-de-semente' target='_BLANK' className='row row-start gap-xs ' >
        <p className="colorBlack"> Laboratório de Análise de Sementes</p>
      </a>

      <a tabIndex={0} href='https://grupoccgl.com.br/app/grupo-ccgl/paginas/pesquisa-e-tecnologia' target='_BLANK' className='row row-start gap-xs ' >
        <p className="colorBlack">Pesquisa e Tecnologia</p>
      </a>
*/}


                <a tabIndex={0} href='https://grupoccgl.com.br' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Laboratório de Análise de Solos</p>
                </a>

                <a tabIndex={0} href='https://grupoccgl.com.br/app' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Laboratório de Análise de Sementes</p>
                </a>

                <a tabIndex={0} href='https://grupoccgl.com.br' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack">Pesquisa e Tecnologia</p>
                </a>



              </div>


              <div className="column-start gap-sm max-300   pad" >
                <h2 style={{ fontSize: '16px', lineHeight: '20px', color: '#333' }}>Comunicação</h2>
                <Link tabIndex={0} to='/#contato' className='row row-start gap-xs ' onClick={() => scrollToID("contato")} >
                  <p className="colorBlack"> Contato</p>
                </Link>
                <a tabIndex={0} href='https://app.ccgl.com.br/site/falecomaccgl' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Fale com a CCGL</p>
                </a>
                <a tabIndex={0} href='https://app.ccgl.com.br/talentos/vagas_disponiveis.php' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Trabalhe conosco</p>
                </a>
                {/*
      <a tabIndex={0} href='https://grupoccgl.com.br/app/grupo-ccgl/paginas/agendar-visitas' target='_BLANK' className='row row-start gap-xs ' >
        <p className="colorBlack"> Agendar Visita</p>
      </a>
*/}
                <a tabIndex={0} href='https://grupoccgl.com.br' target='_BLANK' className='row row-start gap-xs ' >
                  <p className="colorBlack"> Agendar Visita</p>
                </a>
              </div>
            </div>


          </div>
        </div>


      </div>
    </div>
    <p style={{ fontSize: '16px', padding: '30px', paddingBottom: '100px', textAlign: 'center', color: '#333', width: '100%' }}>® 2024 CCGL - Leite de Verdade | Todos os direitos reservados</p>

  </>
  );
};

export default FooterLayout;
