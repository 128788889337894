import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import ContainerLine from '../../components/UIComponents/ContainerLine';
import { Title } from '../Expositor/styles';
import ParagraphDangerous from '../../components/UIComponents/ParagraphDangerous';
import CientificLibrary from '../CientificLibrary';
import CientificLibraryComponent from '../../components/CientificLibrary';
import { ContainerCientific } from '../CientificLibrary/styles';
import { ContainerLineUI } from '../../components/UIComponents/ContainerLine/styles';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Subtitle from '../../components/UIComponents/Subtitle';
import { urlWebsite } from '../../config';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Autores: React.FC = () => {

  const [authors, setAuthors] = useState<Array<Record<string, any>>>();
  const history = useHistory();
  const loadAuthors = async () => {
    const response = await api.get(`/list-cientific-library-authors`);

    if (response.data) {



      response?.data?.rows.sort((a, b) => a?.label > b?.label ? 1 : a?.label < b?.label ? -1 : 0)

      setAuthors(response?.data?.rows || []);

    }

  }

  useEffect(() => {

    loadAuthors();


  }, [])

  const goToAuthor = (name) => {
    history.push(`/conteudos/autores/${name}`);

    window.scrollTo(0, 0);
  }


  return (
    <>

      <ContainerLine className='full-width column column pad '>
        <ContainerLine className='max-1200 column column-start gap'>
          <ContainerLineUI className='full-width column pad'>
            <Title style={{ color: '#555', fontSize: '26px', lineHeight: '32px', padding: '30px 30px' }} className="text-left width-full">Conheça nossos autores</Title>

            <ContainerLineUI className='full-width row row-start gap-sm flex-wrap' style={{ alignItems: 'flex-start' }}  >
              {authors?.map(authorInfo => {

                return <div onClick={() => { goToAuthor(authorInfo?.title) }} className='column max-160 pointer'>
                  <div style={{ width: '150px', height: '150px', borderRadius: '50%', background: `url('${urlWebsite}/${authorInfo.photo}')` }} className="thumb grow" />
                  <Subtitle style={{ color: '#555', display: 'inline-block', fontSize: '10px' }} className="text-center max-200 pad-sm">{authorInfo?.title}</Subtitle>
                </div>

              })}
            </ContainerLineUI>

          </ContainerLineUI>

          <ContainerCientific>
            <CientificLibraryComponent header={false} />

          </ContainerCientific>
        </ContainerLine>
      </ContainerLine>
    </>
  );
};
export default Autores;
