import styled from "styled-components";

interface IProps {
    background: string;
}

export const ImagePopular = styled.div<IProps>`

width: 100%;
padding-bottom: 125%;
background: url("${props => props.background}") no-repeat center;
background-size: cover;
position: relative ;

`;

export const ContainerVideoListDisplay = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
gap: 30px;
margin-top: 50px;
width: 100%;
 

 .popularItem{
     min-width: 200px;
     position: relative ;

 
 }

   

p,.thumbsVideosSlider{
    color: #8b8b8b;
    padding: 10px 30px;
}
h2{
    color: #5b5b5f;
}
 
p{
    line-height: 28px;
}
 

`;