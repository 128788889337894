import React from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { urlWebsite } from '../../../../config';
import { ImagePopular } from '../../../../pages/Home/modules/ReceitasPopulares/styles';
import CategoriesButton from "../../../UIComponents/CategoriesButton";
import { ContainerLineUI } from "../../../UIComponents/ContainerLine/styles";
import ContentTypeButton from "../../../UIComponents/ContentTypeButton";
import MemberButton from "../../../UIComponents/MemberButton";
import Paragraph from "../../../UIComponents/Paragraph";
import ParagraphDangerous from '../../../UIComponents/ParagraphDangerous';

import Subtitle from "../../../UIComponents/Subtitle";
import Title from "../../../UIComponents/Title";

interface IProps {
    className?: string;
    theme?: 'dark' | 'light';
    style?: Record<string, any>;
    filtered?: Array<Record<string, any>>;
    categories?: { label: string, value: string }[];
    unique?: boolean;
    column?: string;
    applySlide?: boolean;
    show?: number;
    setCallback?: Function;

}

interface IRenderContent {
    filteredItems?: Array<Record<string, any>>;
    setCallback?: Function;
}

const renderContentDefault = ({ filteredItems, setCallback = () => { } }: IRenderContent) => {
    const items: Array<JSX.Element> = [];

    filteredItems?.map((item, index) => {

        items.push(<ContainerLineUI tabIndex={0} onClick={() => setCallback(item)} onKeyDown={(event) => {

            if (event.key === 'Enter') {
                setCallback(item)
            }
        }


        } className="popularItem min-200 max-200" >
            <Link title={`Receita: ${item?.title}`} tabIndex={0} onClick={() => window?.scrollTo(0, 0)} to={`/conteudos/acessar/${item?.url}`} key={item?._id} className='  shadow'>
                <ImagePopular className='scaleOnHoverVideo' role="img" aria-label={`Receita: ${item?.title}`} style={{ borderRadius: '10px' }} background={item.image2 ? `${urlWebsite}/${item.image2}` : `${urlWebsite}/${item.image}`}>
                    <FaPlayCircle />
                </ImagePopular>
                <div className='full-width pad-sm column column-start gap-sm' style={{ marginTop: '10px', height: '70px' }}>

                    <h2 className='full-width' style={{ padding: '0px', minHeight: '42px', width: '100%', textAlign: 'center', fontSize: '18px', lineHeight: '22px' }}>{item.title}</h2>

                </div>

            </Link>

        </ContainerLineUI>)

    })

    return items;

}

const DefaultCategoryGallery: React.FC<IProps> = ({ setCallback = () => { }, show = 4, applySlide = false, className, theme, style, filtered = [], categories = [{ value: "all", label: '' }], column }) => {



    return <> {categories?.map(category => {

        const width = window.innerWidth > 1200 ? 1200 : window.innerWidth;
        const maxItems = Math.floor(width / 270) > 1 ? Math.floor(width / 270) : 1;

        const filteredItems = category?.value === 'all' || !column ? filtered : filtered?.filter(item => item?.[column]?.findIndex((text: string) => text === category?.value) >= 0);
        const items = renderContentDefault({ filteredItems: filteredItems, setCallback });

        const showItemsValue = items?.length > maxItems ? maxItems : items?.length;
        const showItems = showItemsValue >= 0 ? showItemsValue : 1;

        return filteredItems && filteredItems?.length > 0 ?
            <ContainerLineUI className={`row row ${!applySlide ? 'flex-wrap' : ''} width-full  gap-sm ${show === 3 ? 'max-900' : 'max-1200'}`} themeValue={theme} style={style}>

                {applySlide ? <Slider responsive={[
                    {
                        breakpoint: 5200,
                        settings: {

                            slidesToShow: show <= items?.length ? show : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {

                            slidesToShow: show <= items?.length ? show : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: (show - 1) <= items?.length ? (show - 1) : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: (show - 2) <= items?.length ? (show - 2) : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]} slidesToShow={show <= items?.length ? show : items?.length}> {items}</Slider> : items}

            </ContainerLineUI>

            : <></>
    })} </>

}

export default DefaultCategoryGallery;