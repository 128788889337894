import React, { useState } from 'react';
import { FaStar } from "react-icons/fa";

interface IStar {
    stars: number;
    setCallback?: Function;
    votes_count_stars?: number;
}

const StarDisplay = ({ stars = 5, votes_count_stars = 0, setCallback = (starsCount) => { } }) => {


    const vote = (starCount) => {

        if (typeof setCallback) {
            setCallback(starCount);
        }

    }

    return <div className='full-width row row-start ' style={{ gap: '3px' }}  >
        <FaStar onClick={() => { vote(1) }} size={20} style={{ cursor: 'pointer', color: stars >= 1 ? 'rgb(255,200,0)' : '#777' }} />
        <FaStar onClick={() => { vote(2) }} size={20} style={{ cursor: 'pointer', color: stars >= 2 ? 'rgb(255,200,0)' : '#777' }} />
        <FaStar onClick={() => { vote(3) }} size={20} style={{ cursor: 'pointer', color: stars >= 3 ? 'rgb(255,200,0)' : '#777' }} />
        <FaStar onClick={() => { vote(4) }} size={20} style={{ cursor: 'pointer', color: stars >= 4 ? 'rgb(255,200,0)' : '#777' }} />
        <FaStar onClick={() => { vote(5) }} size={20} style={{ cursor: 'pointer', color: stars >= 5 ? 'rgb(255,200,0)' : '#777' }} />

        <p style={{ color: '#333', fontSize: '12px', minWidth: '40px', padding: '5px' }}>{stars} / 5</p>
        <p style={{ color: '#333', fontSize: '12px', minWidth: '30px' }}>({votes_count_stars || '0'})</p>
    </div>


}

export default StarDisplay;
