import React, { useEffect, useState } from 'react';
import { FaAccessibleIcon, FaBorderAll, FaEye, FaImage, FaKeyboard } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { MdAccessibility, MdFormatSize } from 'react-icons/md';
import { scrollToID } from '../../utils/scrollTo';

const AccessibilityMenu = () => {
    const [active, setActive] = useState(false);

    const goTo = (ID: string) => {
        scrollToID(ID);
        setActive(false)


    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Verifica se a tecla pressionada é Esc
            if (event.key === 'Escape') {
                setActive(false)

            }
        };

        // Adiciona o ouvinte de eventos ao document
        document.addEventListener('keydown', handleKeyDown);

        // Função de limpeza que remove o ouvinte de eventos quando o componente é desmontado
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return <>

        {!active ? <div title="Recursos de acessibilidade" tabIndex={0} onClick={() => setActive(true)} onKeyDown={(event) => {
            if (event.key === 'Enter') {
                setActive(true);
            }
        }} className='cursor fadeIn  scaleOnHover' style={
            {
                background: "#3a497b",
                border: '2px solid #fff',
                borderRadius: '5px',
                cursor: 'pointer',
                zIndex: 10000000,
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: '12.5px',
                right: '70px'


            }
        }>
            <img src="/apoio/acessibilidade.png" style={{ width: '35px' }} />
        </div> : <></>}

        {active ?

            <div className='fadeIn column pad gap-sm shadow'
                style={{
                    background: "#fff",
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    width: '100%',
                    maxWidth: '400px',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    zIndex: 100000000,
                    overflowY: 'auto',
                    padding: '50px 30px'

                }}

            >

                <p style={{ border: '1px solid #000', cursor: 'pointer', color: '#000', padding: '5px', borderRadius: '5px', margin: '10px 0px' }} tabIndex={0} onClick={() => setActive(false)} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setActive(false);
                    }
                }}>Fechar</p>
                <h2 style={{ color: '#000', fontSize: '18px' }}>Recursos de acessibilidade</h2>
                <div className='column acessibilityMenu'>

                    <nav className='full-width  row-start blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                        <div style={{ minWidth: '70px', color: '#333', padding: '10px' }}   > <FaKeyboard style={{ fontSize: '30px' }} /> </div> <p style={{ color: '#333' }}>Navegação via Teclado <br />
                            [Tab] para avançar<br />
                            [Enter] para acessar <br />
                            [ESC] para fechar um modal
                        </p>
                    </nav>
                    <nav className='full-width  row-start blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                        <div style={{ minWidth: '70px', color: '#333', padding: '10px' }}  > <MdFormatSize style={{ fontSize: '30px' }} /> </div> <p style={{ color: '#333' }}>Aumento de Fonte <br />
                            [Ctrl +] para aumentar<br />
                            [Ctrl -] para diminuir
                        </p>
                    </nav>
                    <nav
                        className='full-width  row-start blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                        <div style={{ minWidth: '70px', color: '#333', padding: '10px' }}  ><FaBorderAll style={{ fontSize: '30px' }} /> </div> <p style={{ color: '#333' }}> Destaque de elementos selecionados </p>
                    </nav>
                    <nav
                        className='full-width  row-start blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                        <div style={{ minWidth: '70px', color: '#333', padding: '10px' }}  >  <FaImage style={{ fontSize: '30px' }} /> </div> <p style={{ color: '#333' }}> Este site conta com descritivo das imagens para leitores de telas</p>
                    </nav>


                </div>
            </div >
            : <></>}

    </>



}

export default AccessibilityMenu;