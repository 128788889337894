import React from 'react';
import { useLanguage } from "../../../hooks/Language";
import { FormLabelUI } from "./styles";

interface IProps {
    children?: React.ReactNode;
    style?: Record<string, any>;
    theme?: 'dark' | 'light';
    className?: string;
}

const FormLabel: React.FC<IProps> = ({ children, style, theme, className }) => {
    const { translate } = useLanguage();

    return <FormLabelUI className={className} themeValue={theme} style={style}>{translate(children)}</FormLabelUI>;
}

export default FormLabel;