import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash, urlWebsite } from '../../../../config';
import IframePlayer from '../../../../components/IframePlayer';
import { Rodape } from './styles';
import RodapeLP from '../RodapeLP';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const RodapeELivro: React.FC<IParam> = () => {



    return <Rodape className='column'>
        <div className='max-1200 row-to-column' style={{ width: '100%', margin: '0px auto' }}>
            <div className='content1' >
                <img className='book' src={'/apoio/book2.png'} />
            </div>
            <div className='content2'>


                <div className='full-width bookLine' style={{ marginBottom: "-20px" }} >
                    <h2 className='titleH2 max-400' style={{ width: '100%' }}>BAIXE O LIVRO</h2>

                    <div className='row row-start  max-300' style={{ padding: '0px 15px', marginTop: '50px' }}>
                        <button className='greenButton2Reverse' style={{ marginLeft: '20px' }}>CLIQUE AQUI</button>
                    </div>
                    <div className='greenButton2Big leftElement'  >
                        <div className='greenButton2Big leftElement2' />

                    </div>
                </div>
            </div>
        </div>


    </Rodape>


}

export default RodapeELivro;