import styled from "styled-components";

export const ContainerVideoListDisplay = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
gap: 30px;
margin-top: 50px;
width: 100%;
 
@media (max-width: 750px) {
    width: 100%;
    max-width: 100%;
    
  }
img{
            width: 100%;
        }

        .content1{
            width: 55%;
    
    max-width: 660px; 
    @media (max-width: 750px) {
    width: 100%;
    
  }
        }

.content2{
    width: 45%;
    
    max-width: 540px;

    background:  #fff; 
    border-radius: 15px;
    padding: 30px;

    @media (max-width: 750px) {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    
  }
}

.content2{
    gap: 15px;
    display: flex;
align-items: flex-start;
justify-content: stretch;
flex-direction: column;
}

h2,p,.thumbsVideosSlider{
    color: #8b8b8b;
    padding: 10px 30px;
}

 
p{
    line-height: 28px;
}

.thumbsVideosSlider{
    width: 100% ;
    overflow: hidden;
    display: flex ;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 5px;

    .thumbsVideos{
        width: 100% ;
        max-width: 200px;
        min-width: 200px;
       background:#333;
       border-radius: 15px;

    }
}

`;