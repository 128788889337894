import React, { useCallback, useRef, useEffect, useState } from 'react';

import { FiMail } from 'react-icons/fi';
import { Container } from './styles';
import Vimeo from '../Vimeo';
import Button from '../UIComponents/Button';
import { useModal } from '../../hooks/Modal';
import SendEmailToPoster from '../SendEmailToPoster';
import { Modal } from '../ModalContainer/styles';
import ChatRoom from '../Chat';
import Helmet from 'react-helmet';
import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';
import { urlWebsite } from '../../config';
import { FaChevronLeft, FaDownload, FaFilePdf, FaHeart, FaImage, FaNewspaper, FaQuestion, FaReceipt, FaStar, FaWhatsapp } from 'react-icons/fa';
import { ShowStars, track } from '../CientificLibrary';
import PrintBook from '../PageFlip';
import { Fixed } from '../ImageGallery/styles';
import { MdFullscreen } from 'react-icons/md';
import SignIn from '../../pages/SignInCientificLibrary';
import { useAuth } from '../../hooks/Auth';
import CopyToClipboard from '../CopyToClipboard';
import { useToast } from '../../hooks/Toast';
import { rgba } from 'polished';
import themeDefault from '../../styles/ThemeComponents';
import { useHistory } from 'react-router-dom';
import IframePlayer from '../IframePlayer';
import { ContainerLineUI } from '../UIComponents/ContainerLine/styles';
import Title from '../UIComponents/Title';
import Paragraph from '../UIComponents/Paragraph';
import DefaultCategoryGallery from '../CientificLibrary/modules/DefaultCategoryGallery';
import Subtitle from '../UIComponents/Subtitle';
import ParagraphDangerous from '../UIComponents/ParagraphDangerous';
import { url } from 'inspector';
import { useInteraction } from '../../hooks/Interactions';
import StarDisplay from '../../pages/Home/modules/StarDisplay';
import { Form } from '@unform/web';
import StarInput from '../../pages/DashboardPages/core/components/Forms/StarInput';
import { loadSimilar } from '../CientificLibrary/functions/loadSimilar';
import { handleDownloadInfo } from '../../pages/DashboardPages/pages/CertificatesUsers/modules/ExportPDF';
import { scrollToID } from '../../utils/scrollTo';


interface IPoster {
  _id: string;
  id: number;
  title?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  formatted_date?: string;
  formatted_start_hour?: string;
  room_id?: string;
  hash?: string;
  description?: string;
  url?: string;
  pdf?: string;
  pdf_images?: Array<Record<string, any>>;
  pdf_links: Array<Record<string, any>>;
  extra_links: Array<Record<string, any>>;
  keywords: Array<string>;
  star?: number;
  download_files?: Array<Record<string, any>>;
  nested_authors?: string;
  interactions?: Record<string, any>;
  authors?: Record<string, any>[];
  big_area?: string;
  video_url?: string;
  average_stars?: number;
  votes_count_stars?: number;
}

interface IPosterItem {
  posterItem: IPoster;
  closeFunction: Function;

}

interface IZoomPoster {
  posterID: string;
  closePoster: Function;
  closeFunction: Function;
  posters: Array<Record<string, any>>;
}

interface IStars {
  stars: number;
  updateInteraction: Function;
}

interface IProp {
  url: string;
}

const OpenPDF: React.FC<IProp> = ({ url }) => {



  const [embed, setEmbed] = useState('');


  function base64Encode(str) {
    var CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var out = "", i = 0, len = str.length, c1, c2, c3;
    while (i < len) {
      c1 = str.charCodeAt(i++) & 0xff;
      if (i == len) {
        out += CHARS.charAt(c1 >> 2);
        out += CHARS.charAt((c1 & 0x3) << 4);
        out += "==";
        break;
      }
      c2 = str.charCodeAt(i++);
      if (i == len) {
        out += CHARS.charAt(c1 >> 2);
        out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
        out += CHARS.charAt((c2 & 0xF) << 2);
        out += "=";
        break;
      }
      c3 = str.charCodeAt(i++);
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
      out += CHARS.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
      out += CHARS.charAt(c3 & 0x3F);
    }
    return out;
  }

  const load = async () => {

    const item = await api.get(url);

    if (item) {
      const base = base64Encode(item.data);

      setEmbed(base);
    }

  }



  return url ? <iframe src={url} style={{ width: '100%', height: '100vh' }}></iframe> : <h2>Carregando...</h2>
}


export const ShowStarsHandler: React.FC<IStars> = ({ stars = 0, updateInteraction = () => { } }) => {

  const [countStars, setCountStars] = useState(0);

  useEffect(() => {
    setCountStars(stars);
  }, [stars])

  return <div className='starCase'>

    <FaStar onClick={() => { updateInteraction(1); setCountStars(1); }} size={15} style={{ color: countStars >= 1 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar onClick={() => { updateInteraction(2); setCountStars(2); }} size={15} style={{ color: countStars >= 2 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar onClick={() => { updateInteraction(3); setCountStars(3); }} size={15} style={{ color: countStars >= 3 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar onClick={() => { updateInteraction(4); setCountStars(4); }} size={15} style={{ color: countStars >= 4 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar onClick={() => { updateInteraction(5); setCountStars(5); }} size={15} style={{ color: countStars >= 5 ? themeDefault.alternativeButton.background : '#fff' }} />
  </div>

}


const CientificLibrarySidebar: React.FC<IPosterItem> = ({ posterItem }) => {
  const { user } = useAuth();
  const [currentState, setCurrentState] = useState('description');

  const changeState = (type) => {

    setCurrentState(type)

  }

  const updateInteractions = (value) => {


    api.post(`/cientific-library-add-interaction/${posterItem._id}`, { star: value })

  }


  return <div className='sidebar'>
    <div className='sidebarNav'>
      <nav title="Informações" onClick={() => changeState('description')}><FaNewspaper size={25} /> </nav>
      <nav title="Download" onClick={() => { changeState('download') }}><FaDownload size={25} /> </nav>
      <nav title="Dúvidas" onClick={() => changeState('information')}><FaQuestion size={25} /></nav>
      <nav title='Copiar link do conteúdo'> <CopyToClipboard text={`${urlWebsite}/conteudos/acessar/${posterItem.url}`} /></nav>
      <nav title='Compartilhar via Whatsapp'>
        <a target='_BLANK' href={`https://api.whatsapp.com/send?l=pt&text=${encodeURI(
          `Olá! Gostaria de compartilhar com você o conteúdo ${posterItem.title} do CCGL pelo Brasil. Link: ${urlWebsite}/biblioteca-cientifica/artigos/${posterItem.url}`
        )}`} onClick={() => track({ title: posterItem.title, id: posterItem._id, reference: 'whatsapp-share', track: 'whatsapp-button', client_id: user?.id || '', client_name: user?.name || '' })}>
          <FaWhatsapp size={27} color="#fff" />
        </a> </nav>

    </div>


    <div>


      <div style={{ display: currentState === 'information' ? 'flex' : 'none' }} className='posterContent'>

        <p style={{ marginBottom: '15px' }}>Caso tenha dúvidas ou precise de alguma informação adicional, acesse nossos canais abaixo:</p>


        <a className='fileDownload' target='_BLANK' href={`https://api.whatsapp.com/send?l=pt&phone=5540202446&text=${encodeURI(
          `Olá, estou na da CCGL pelo Brasil, no artigo ${posterItem.title} e gostaria de mais informações`
        )}`} onClick={() => track({ title: posterItem.title, id: posterItem._id, reference: 'information-contact', track: 'whatsapp-button', client_id: user?.id || '', client_name: user?.name || '' })}>
          <FaWhatsapp size={25} /> <p>{`4020-2446`}</p>
        </a>



      </div>


      <div style={{ display: currentState === 'download' ? 'flex' : 'none' }} className='posterContent'>

        <a className='fileDownload' href={`${urlWebsite}/${posterItem.pdf}`} download onClick={() => track({ title: posterItem.title, id: posterItem._id, reference: 'download', track: 'PDF', client_id: user?.id || '', client_name: user?.name || '' })}>
          <FaDownload size={25} /> <p>{`PDF - ${posterItem.title}`}</p>
        </a>

        {
          posterItem?.download_files && posterItem?.download_files.length > 0 && posterItem?.download_files.map(item => {

            return <a className='fileDownload' style={{ marginTop: '15px' }} key={`${urlWebsite}/${item.url}`} href={`${urlWebsite}/${item.url}`} download onClick={() => track({ title: posterItem.title, reference: 'download', id: posterItem._id, track: `Arquivo de Apoio - ${item.title}`, client_id: user?.id || '', client_name: user?.name || '' })}>
              <FaDownload size={25} /> <p>{`${item.title}`}</p>
            </a>

          })
        }




      </div>


      <div style={{ display: currentState === 'description' ? 'flex' : 'none' }} className='posterContent'>

        <h2> {posterItem.title}</h2>


        <p className="posterAuthor">


          {posterItem?.nested_authors}

        </p>



        {posterItem?.keywords?.length > 0 && <div className='posterKeywords'>
          {posterItem?.keywords?.length > 0 && posterItem.keywords.map((keyword, indexKeyword) => {

            return indexKeyword <= 2 ? <strong>{keyword} </strong> : <></>

          })}

        </div>}

        <ShowStarsHandler updateInteraction={(value) => updateInteractions(value)} stars={posterItem?.interactions?.star || 0} />

        <p style={{ fontSize: '14px', color: '#fff', marginTop: '15px' }} dangerouslySetInnerHTML={{ __html: posterItem.description || '' }} />

      </div>


    </div>

  </div>
}

const CientificLibraryRoomComponent: React.FC<IZoomPoster> = ({
  posterID,
  closeFunction,
  posters
}) => {
  const { addModal, removeModal } = useModal();
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [posterItem, setPosterItem] = useState<IPoster>({} as IPoster);
  const [scaleImg, setScaleImg] = useState(100);
  const [typeImg, setTypeImg] = useState(1);
  const [active, setActive] = useState(false);
  const { translate } = useLanguage();
  const { interactions, addInteraction } = useInteraction();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [ready, setReady] = useState(false);

  const [type, setType] = useState('imagem');

  const [openBook, setOpenBook] = useState(false);
  const { user, signOut } = useAuth();
  const ref = useRef(null);
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    if (posterItem?.url && window?.location?.pathname?.indexOf(posterItem.url) < 0) {

      history.push(`/conteudos/acessar/${posterItem.url}`);
    }


  }, [posterItem]);





  const load = async () => {

    try {

      const poster = await api.get(`/get-cientific-library/${posterID}`)

      if (!poster) {
        //  addToast({ title: 'Falha de autenticação, por favor, faça seu login novamente', type: 'error' });
        return
      }




      if (poster?.data?._id) {

        track({ id: poster.data._id, title: poster.data.title, track: "cientific-library", reference: 'access', client_id: user?.id, client_name: user?.name })

        setPosterItem(poster.data);
      }

    }
    catch (err) {

      if (err?.response?.status === 402 || err?.response?.status === 403) {
        signOut();

      }

      if (err?.response?.status === 401) {
        signOut();

      }


    }




  }



  useEffect(() => {

    load();



  }, [posterID, user])


  const elementsItems = [
    {
      name: 'Parte 1',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 0.3,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 2',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 0.3,
      width: 33,
      height: 42,
    },
    {
      name: 'Parte 3',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 33,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 4',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 33,
      width: 33,
      height: 42,
    },
    {
      name: 'Parte 5',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 20,
      left: 67,
      width: 33,
      height: 37,
    },
    {
      name: 'Parte 6',
      type: 1,
      transform: ` scale(1) translateX(0) translateY(0)`,
      top: 58,
      left: 67,
      width: 33,
      height: 42,
    },
  ];

  const triangule = (a, b) => {

    return (b / a) * 100;

  }

  const [renderer, setRenderer] = useState(elementsItems);
  const [indexer, setIndexer] = useState([0, 2]);


  const [postersSimilar, setPostersSimilar] = useState([]);

  useEffect(() => {

    if (posterItem?.url) {
      loadSimilar(posterItem, setPostersSimilar);
    }

  }, [posterItem])

  const zoom = (index: number, type: number) => {
    setIndexer([index, type]);
    const elements = [...elementsItems];
    const element = { ...elementsItems[index] };
    const { width, height, left, top } = element;

    const vetor = 95 / width;
    const getScale = vetor * 100;

    elements.map((elementIt, indexIt) => {
      elements[indexIt].type = 1;
    });

    element.type = type === 1 ? 2 : 1;
    // window.scrollTo(0, 0);

    elements.splice(index, 1, element);
    const tvetor = 1080 / 1920;
    const tX =
      left * vetor < 0 ? (left * vetor - 2.5) * -1 : left * vetor - 2.5;


    setTranslateX(type === 1 ? left * (type === 1 ? 2.8 : 1) : 0);
    setTranslateY(type === 1 ? top * 0.5 * vetor : 0);
    setScaleImg(type === 1 ? getScale : 100);
    setTypeImg(type === 1 ? 2 : 1);
    setRenderer([...elements]);
  };

  const getPlayer = () => {
    const [index, type] = indexer;

    if (type === 2) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closeFunction()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <button type="button" onClick={() => zoom(0, 1)}>
              {translate('Iniciar')}
            </button>
          )}
        </>
      );
    }

    const length = elementsItems.length - 1;

    if (index === 0 && length > 0) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closeFunction()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(0, 2)}>
                {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(index + 1, 1)}>
                {translate('Próximo')}
              </button>
            </>
          )}
        </>
      );
    }

    if (index > 0 && length > index) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closeFunction()}
          >
            Fechar
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(index - 1, 1)}>
                {translate('Anterior')}
              </button>
              <button type="button" onClick={() => zoom(0, 2)}>
                {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(index + 1, 1)}>
                {translate('Próximo')}
              </button>
            </>
          )}
        </>
      );
    }

    if (index > 0 && length === index) {
      return (
        <>
          <button
            className="closeButton"
            type="button"
            onClick={() => closeFunction()}
          >
            {translate('Fechar')}
          </button>
          {posterItem.have_video === 1 && (
            <>
              <button type="button" onClick={() => zoom(index - 1, 1)}>
                {translate('Anterior')}
              </button>
              <button type="button" onClick={() => zoom(0, 2)}>
                {translate('Tela Cheia')}
              </button>
              <button type="button" onClick={() => zoom(0, 1)}>
                {translate('Voltar ao início')}
              </button>
              {' '}
            </>
          )}
        </>
      );
    }

    return <></>;
  };

  const showForm = () => {
    addModal({
      key: 'messageToPoster',
      title: translate('Enviar Mensagem'),
      content: <SendEmailToPoster hash={posterItem.hash} callback={() => { }} />,
    });
  };


  const goToAuthor = (name) => {
    history.push(`/conteudos/autores/${name}`);
    closeFunction();
    window.scrollTo(0, 0);
  }


  const generateSingleLinks = () => {

    const extra_links = posterItem?.extra_links ? posterItem?.extra_links.reduce((prev, itemLink) => {

      if (parseInt(itemLink?.page, 10) === 1) {
        prev.push(itemLink);
      }

      return prev;

    }, []) : [];


    return <> {extra_links.map(linkInfo => {


      return <a onClick={() => track({ title: posterItem.title, id: posterItem._id, reference: 'internal_link', track: linkInfo.url, client_id: user?.id || '', client_name: user?.name || '' })} key={`link-${linkInfo.url}`} style={{
        position: 'absolute', background: 'rgba(0,0,200,0.1)',
        top: linkInfo?.position_y || '0px',
        left: linkInfo?.position_x || '0px',
        width: linkInfo?.width || '0px',
        height: linkInfo?.height || '0px',
      }} href={`${linkInfo.url}`} target='_BLANK' title={linkInfo.title}> </a>

    })
    }</>;

  }

  const submitVote = async (star, id) => {
    try {
      const vote = await api.post(`/send-stars-avaliation/${id?.toString()}`, { star });
      addToast({ title: 'Avaliação submetida com sucesso!', type: 'success' });
      removeModal('star');

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao confirmar sua avaliação', type: 'error' });
    }

  }

  const confirmStars = async (poster) => {

    const check = await api.get(`/check-stars-avaliation/${poster?._id?.toString()}`);

    if (!check?.data?._id) {
      addModal({
        title: '', key: 'star', content: <>
          <h2>Avalie nosso conteúdo</h2>
          <Form initialData={{ star: 0 }} onSubmit={({ star }) => submitVote(star, poster?._id?.toString())}>
            <StarInput name='star' />
            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <button className='greenButton2'>Avaliar</button>
            </aside>
          </Form>
        </>, theme: 'whiteModalMedium'
      })
    }
    else {
      addModal({
        title: '', key: 'star', content: <>
          <h2>Obrigado por sua avaliação!</h2>
          <p>Sua avaliação foi computada em nossa plataforma!</p>
        </>, theme: 'whiteModalMedium'
      })
    }


  }


  useEffect(() => {
    setTimeout(() => {
      scrollToID('content')
    }, 300)


  }, [posterID])


  return (
    <Container id='content' style={{ paddingTop: '50px' }}>



      <div className="posterPlace column full-width">
        <ContainerLineUI className='full-width row row-to-column pad space-between' style={{ paddingBottom: '15px' }}  >
          <div className='pointer' onClick={() => { closeFunction(); history.goBack(); window.scrollTo(0, 0) }}> <Subtitle style={{ color: '#8b8b8b', fontSize: '12px' }} className="text-left width-full"><FaChevronLeft /> VOLTAR</Subtitle></div>



        </ContainerLineUI>
        <ContainerLineUI className='full-width row ' style={{ alignItems: 'center', padding: '0px 30px' }}  >
          <Title style={{ color: '#fff', fontSize: '16px', lineHeight: '20px' }} className="text-left width-full">{posterItem?.title}</Title>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <StarDisplay setCallback={(starsValue) => confirmStars(posterItem)} votes_count_stars={posterItem?.votes_count_stars} stars={posterItem?.average_stars || 5} />

            <div className='heartLocal shadow' onClick={() => addInteraction({ id: posterItem?._id?.toString(), type: 'saving', subtype: 'content', value: posterItem?.title || '' })}>
              <FaHeart color={interactions?.saving?.[posterItem?._id?.toString()] ? 'red' : '#333'} />
            </div>
            <div style={{ marginLeft: '15px', }} title="Baixar receita em pdf" className='heartLocal2 shadow' onClick={() => handleDownloadInfo({ image: posterItem?.image, title: posterItem?.title, description: posterItem?.description, width: '15cm', height: 'auto' })}>
              <FaDownload color={'#333'} />
            </div>

          </div>
        </ContainerLineUI>
        <ContainerLineUI className='full-width row row-to-column ' style={{ padding: '15px 30px' }}>
          <IframePlayer id={posterItem?.video_url || ''} />
        </ContainerLineUI>

        <ContainerLineUI className='full-width column pad ' style={{ background: "#eee" }} >
          {posterItem?.description ? <ParagraphDangerous text={posterItem?.description || '<p></p>'} /> : <></>}
        </ContainerLineUI>


        <ContainerLineUI className='full-width column pad'  >
          <ContainerLineUI className='max-1200 column column pad' style={{ padding: '15px 30px' }}>
            <h2 className="titleH2">Conheça mais receitas</h2>

          </ContainerLineUI>
          <DefaultCategoryGallery setCallback={(poster) => { history.push(`/conteudos/acessar/${poster.url}`); window?.scrollTo(0, 0) }} filtered={postersSimilar} />
        </ContainerLineUI>

      </div>

      <Helmet>
        <title>{posterItem.title}</title>

      </Helmet>
    </Container>
  );
};

export default CientificLibraryRoomComponent;
