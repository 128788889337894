import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaHeart } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash, urlWebsite } from '../../../../config';
import IframePlayer from '../../../../components/IframePlayer';
import { ContainerVideoListDisplay, ImagePopular } from './styles';
import StarInput from '../../../DashboardPages/core/components/Forms/StarInput';
import StarDisplay from '../StarDisplay';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { defaultProjectHash } from '../../../../config';
import { useInteraction } from '../../../../hooks/Interactions';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const ReceitasPopulares: React.FC<IParam> = () => {
    const [conteudos, setConteudos] = useState<Array<Record<string, any>>>([]);

    const { interactions, addInteraction } = useInteraction();

    const load = async () => {

        const items = await api.get(`/list-cientific-library`, { params: { where: { project_id: defaultProjectHash, status: 'yes' } } });

        setConteudos(items?.data?.rows);




    }

    useEffect(() => {
        load();
    }, [])


    return <ContainerVideoListDisplay className='max-1200'>


        <div className='full-width max-1200 column column-start gap'>
            <h2 className='titleH2'>RECEITAS POPULARES</h2>

            <div className=' full-width row row-start' style={{ marginTop: '20px' }}>
                <Slider responsive={[
                    {
                        breakpoint: 5200,
                        settings: {

                            slidesToShow: 4 <= conteudos?.length ? 4 : conteudos?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {

                            slidesToShow: 4 <= conteudos?.length ? 4 : conteudos?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3 <= conteudos?.length ? 3 : conteudos?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: 2 <= conteudos?.length ? 2 : conteudos?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]} slidesToShow={4} slidesToScroll={1} infinite arrows={false}>
                    {conteudos?.map(conteudo => {
                        return <div className='popularItem'> <Link title={`Receita: ${conteudo?.title}`} tabIndex={0} onClick={() => window?.scrollTo(0, 0)} to={`/conteudos/acessar/${conteudo?.url}`} key={conteudo?._id} className='  shadow'>
                            <ImagePopular role="img" aria-label={`Receita: ${conteudo?.title}`} background={conteudo.image2 ? `${urlWebsite}/${conteudo.image2}` : `${urlWebsite}/${conteudo.image}`}>

                            </ImagePopular>
                            <div className='full-width pad-sm column column-start gap-sm' style={{ marginTop: '20px', height: '168px' }}>
                                <StarDisplay stars={conteudo?.average_stars || 5} votes_count_stars={conteudo?.votes_count_stars} />
                                <h2 className='full-width' style={{ padding: '0px', minHeight: '56px' }}>{conteudo.title}</h2>

                            </div>

                        </Link>
                            <div className='heart shadow' onClick={() => addInteraction({ id: conteudo?._id?.toString(), type: 'saving', subtype: 'content', value: conteudo?.title })}>
                                <FaHeart color={interactions?.saving?.[conteudo?._id?.toString()] ? 'red' : '#333'} />
                            </div>
                        </div>

                    })}
                </Slider>
            </div>

        </div>


    </ContainerVideoListDisplay>


}

export default ReceitasPopulares;