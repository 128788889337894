import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaYoutubeSquare } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash, urlWebsite } from '../../../../config';
import IframePlayer from '../../../../components/IframePlayer';
import { Rodape } from './styles';
import RodapeELivro from '../RodapeELivro';
import LeadForm from '../../../LeadForm';
import { Link } from 'react-router-dom';
import useReceitas from '../../../LeadEnvieSuaReceita/hook/useLeadReceita';
import { useConfiguration } from '../../../../hooks/Configuration';
import { ContainerLineUI } from '../../../../components/UIComponents/ContainerLine/styles';
import { simpleScrollTo } from '../../../../components/MenuMini';
import { ParagraphUI } from '../../../../components/UIComponents/Paragraph/styles';
import Contact from '../../../../PagesApplication/HomeApplication/modules/Home/modules/Contact';

interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const RodapeLP: React.FC<IParam> = () => {

    const { sendLead } = useReceitas();

    const { configuration } = useConfiguration();


    return <>
        {window?.location?.pathname === '/ss' ? <RodapeELivro /> : <></>}

        <Rodape className='column full-width'>
            <div className='max-1200 row-to-column' style={{ width: '100%', paddingTop: '100px', margin: '0px auto' }}>
                <div className='content1 pad' >
                    <img src="/apoio/selo.png" className='max-400' />
                </div>
                <div className='content2'>
                    <div style={{ marginBottom: '5px' }}>
                        <div className='footerMenu column column-start gap-sm'>
                            <strong className='menuBlack' style={{ marginBottom: '15px' }}>Menu</strong>

                            {configuration?.active_pages?.map(i => {
                                return i?.active === 'yes' ? <> {i?.type === 'external' ? <a href={i?.url} target="_BLANK" className='menuFont' tabIndex={0}>{i?.title}</a> : <Link tabIndex={0} className='menuFont' onClick={() => window?.scrollTo(0, 0)} to={i?.url}>- {i?.title}</Link>}
                                    {i?.submenu ? i?.submenu?.map(sub => {
                                        return <Link tabIndex={0} className='menuFont' onClick={() => window?.scrollTo(0, 0)} to={sub?.url}>- -  {sub?.title}</Link>
                                    }) : <></>}
                                </> : <></>
                            })}



                            {/*    <a className='menuFont' href='/apoio/livro-de-receitas.pdf' download>- Livro de receitas</a> */}
                            {/*       <nav className='menuFont cursor' onClick={() => { sendLead() }}>- Envie sua receita</nav> */}
                        </div>
                        <div className='footerSocial'></div>
                    </div>
                    <LeadForm />
                </div>
            </div>


        </Rodape>
        <ContainerLineUI tabIndex={0} id='contato' className='gap-bg column ' style={{ minHeight: '10vh', padding: '50px 15px', paddingBottom: '0px', background: '#fff', zIndex: 1000 }}>

            <div className='full-width '>
                <div className='full-width home1 column' style={{ position: 'relative' }}>

                    <div className='full-width max-1200 column  pad '>
                        <div className='full-width max-1000 column  gap   column-start-mobile' style={{ padding: '15px 30px' }}>


                            <div className=" column gap">
                                <div className="column-start max-600" style={{ gap: '10px', width: '100%' }}>
                                    <h2 className='title color6 text-center full-width ' style={{ fontSize: '16px', lineHeight: '20px', margin: '0px', padding: '0px', textAlign: 'left' }}>FALE COM A CCGL</h2>
                                    <h2 className='title color6 text-center full-width ' style={{ fontSize: '32px', lineHeight: '36px', margin: '0px', padding: '0px', textAlign: 'left' }}>Como podemos te ajudar?</h2>
                                    <ParagraphUI style={{ fontSize: '14px' }}>Estamos aqui para ouvir você! Sinta-se à vontade para compartilhar suas sugestões, elogios ou reclamações. Nossa equipe está pronta para te atender com toda a atenção e carinho que você merece.</ParagraphUI>
                                    <a tabIndex={0} href='https://app.ccgl.com.br/site/falecomaccgl' target='_BLANK' className='row row-start gap-xs ' >
                                        <button className='greenButton2 color6' style={{ fontWeight: 400 }}> Registre aqui</button>
                                    </a>
                                </div>


                            </div>

                        </div></div>
                </div></div></ContainerLineUI>
        <ContainerLineUI tabIndex={0} className='gap-bg column ' style={{ minHeight: '10vh', padding: '50px 0px', paddingTop: '0px', background: '#fff', zIndex: 1000 }}>

            <div className='full-width '>
                <div className='full-width home1 column' style={{ position: 'relative' }}>

                    <div className="column pad" style={{ background: '#4f659c' }}>
                        <Contact />
                    </div>

                </div></div></ContainerLineUI>
        <ContainerLineUI tabIndex={0} className='gap-bg column ' style={{ minHeight: '10vh', padding: '50px 15px', background: '#fff', zIndex: 1000 }}>

            <div className='full-width '>
                <div className='full-width home1 column' style={{ position: 'relative' }}>


                    <div className='full-width max-1200 column  pad '>
                        <div className='full-width max-1000 column  gap   column-start-mobile' style={{ padding: '15px 30px' }}>

                            <img src='/apoio/ccgl.png' className=" max-200  " style={{ margin: '0px' }} />

                            <div className="row gap-xs row-start-mobile ">

                                <a tabIndex={0} href="https://www.instagram.com/ccgloficial/?igsh=MWN3bzJzYzVpNGc4eg%3D%3D" target='_BLANK' className='row row-start gap-sm ' style={{ width: '40px' }}>
                                    <FaInstagramSquare style={{ minWidth: '40px', fontSize: '40px', color: 'rgb(79, 101, 156)' }} />
                                </a>


                                <a tabIndex={0} href='https://www.facebook.com/grupoccgl?mibextid=ZbWKwL' target='_BLANK' className='row row-start gap-sm ' style={{ width: '40px' }}>
                                    <FaFacebookSquare style={{ minWidth: '40px', fontSize: '40px', color: 'rgb(79, 101, 156)' }} />
                                </a>
                                <a tabIndex={0} href='https://www.linkedin.com/company/grupoccgl/' target='_BLANK' className='row row-start gap-sm ' style={{ width: '40px' }}>
                                    <FaLinkedin style={{ minWidth: '40px', fontSize: '40px', color: 'rgb(79, 101, 156)' }} />
                                </a>
                                <a tabIndex={0} href='https://www.youtube.com/@ccgl_leitedeverdade' target='_BLANK' className='row row-start gap-sm ' style={{ width: '40px' }}>
                                    <FaYoutubeSquare style={{ minWidth: '40px', fontSize: '40px', color: 'rgb(79, 101, 156)' }} />
                                </a>


                            </div>


                            <div className="row-to-column row  full-width max-1000 gap column-start-mobile" style={{ alignItems: 'flex-start' }}>




                                <div className="  max-400">

                                    <p className='paragraph color6 pad' >
                                        <strong className='color6'>ESCRITÓRIO COMERCIAL</strong><br />
                                        Rua Marquês do Pombal, 1854 <br />
                                        Bairro Higienópolis 90540-000<br />
                                        Porto Alegre - RS - Brasil<br />
                                        <br />
                                        (51) 3337-7256 | 3019-8117<br />
                                        <br />
                                        comercial@ccgl.com.br  </p>
                                </div>

                                <div className="  max-400">

                                    <p className='paragraph color6 pad' >
                                        <strong className='color6'>UNIDADE DE LATICÍNIOS</strong><br />
                                        Rodovia RS 342 - Km 149 <br />
                                        98015-562<br />
                                        Cruz Alta - RS - Brasil<br />
                                        <br />
                                        (55) 3321.9400<br />

                                    </p>
                                </div>





                            </div>







                        </div>


                    </div>


                    <div className='full-width max-800 column  gap ' style={{ padding: '15px 30px', }}>



                        <div className="full-width row row-to-column max-1200 gap-sm" style={{ alignItems: 'flex-start' }}>



                            <div className="row-to-column gap max-300  pad" >
                                <Link to='/' target='_BLANK' className='row row-start gap-sm ' >
                                    <p className="color6"> <strong className='color6'>Home</strong></p>
                                </Link>
                                <a tabIndex={0} href='https://receitas.ccgl.com.br/' target='_BLANK' className='row-start  gap-xs column-start-mobile ' >
                                    <p className="color6"> <strong className='color6'>Receitas</strong></p>
                                </a>
                                <a tabIndex={0} href='https://www.youtube.com/@ccgl_leitedeverdade' target='_BLANK' className='row-start   gap-xs column-start-mobile ' >
                                    <p className="color6"> <strong className='color6'>Vídeos</strong></p>
                                </a>
                                <Link tabIndex={0} to='/#contato' className='row row-start gap-xs ' onClick={(e) => { e.preventDefault(); simpleScrollTo('contato') }}>
                                    <p className="color6"> <strong className='color6'>Contato</strong></p>
                                </Link>
                            </div>




                        </div>

                    </div>

                </div>
            </div>



        </ContainerLineUI>
    </>

}

export default RodapeLP;