import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useField, FormHandles } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';
import { Error } from '../styles';
import { FaStar } from 'react-icons/fa';
import { urlWebsite } from '../../../../../../config';
import { useToast } from '../../../../../../hooks/Toast';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;

  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  image?: string;
}
const StarInput: React.FC<Props> = ({
  name,
  icon: Icon,
  setCallback = () => { },

  formRef,
  image = '',
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const { addToast } = useToast();
  const [hoverValue, setHoverValue] = useState<number>(0); // Adicionado para rastrear o valor de hover

  const [currentValue, setCurrentValue] = useState<number>(defaultValue);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFilled(!!inputRef.current);

    const length = setIsFocus(false);
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {
        console.log(currentValue);
        return currentValue;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const itemChange = () => {
    setTimeout(() => {
      setCallback(currentValue);
    }, 1000)
  }

  const changeValue = (v) => {
    setCurrentValue(v);
    itemChange();
    // addToast({type: 'success', title: 'adicionado'})

  }

  return (
    <Container style={{ margin: '10px auto', width: '350px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', border: '0px', padding: '0px', background: '#fdfdfd', boxShadow: '0px 2px 15px rgba(0,0,0,0.3)', borderRadius: '10px' }} isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <div className='starInput' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
        {
          image ? <div style={{ width: '100%' }}>
            <img src={`${urlWebsite}/${image}`} style={{ width: '100%', borderRadius: '15px 15px 0px 0px' }} />
          </div> : <></>}
        <div>
          {rest.placeholder ? <p style={{ padding: '10px', width: '100%', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: rest.placeholder || '' }} /> : <></>}
          <div style={{ display: 'flex', padding: '20px 0px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {[1, 2, 3, 4, 5].map((value) => (
              <FaStar
                key={value}
                size={30}
                onMouseEnter={() => setHoverValue(value)} // Define o valor de hover ao passar o mouse
                onMouseLeave={() => setHoverValue(0)} // Reseta o valor de hover ao remover o mouse
                onClick={() => changeValue(value)}
                style={{
                  cursor: 'pointer',
                  // Muda a cor baseado no hover ou no valor selecionado
                  color: value <= (hoverValue || currentValue) ? 'rgb(255,200,0)' : '#777',
                }}
              />
            ))} </div>







        </div>
      </div>
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default StarInput;
