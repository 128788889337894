import React, { useState, useEffect } from 'react';

import { FiUsers, FiUserPlus, FiCreditCard, FiUserCheck, FiMenu, FiBarChart, FiMessageSquare, FiLayout } from 'react-icons/fi';
import { FaArrowLeft, FaBook, FaBox, FaBoxes, FaBuilding, FaCalendar, FaCalendarDay, FaCertificate, FaChartArea, FaChartBar, FaComment, FaCreditCard, FaFileAudio, FaFileContract, FaFilePdf, FaGifts, FaHome, FaImage, FaImages, FaLightbulb, FaMeetup, FaMicrophone, FaNewspaper, FaPage4, FaPagelines, FaPenSquare, FaPlusCircle, FaPodcast, FaProjectDiagram, FaQuestion, FaReceipt, FaRegSmileBeam, FaSlideshare, FaSpeakerDeck, FaSquareFull, FaStar, FaSubscript, FaTicketAlt, FaUser, FaUtensilSpoon, FaVideo, FaVideoSlash, FaVoteYea } from 'react-icons/fa';


import CounterCard from './core/components/CounterCard';
import CounterCardRepeat from './core/components/CounterCardSocket';
import api from '../../services/api';
import {
  Container,

  CardContainer,
  RoomReport, RoomReportDiv,
  Sidebar
} from './styles';

import { useAuth } from '../../hooks/Auth';
import Lessons from './pages/Lessons';
import Sale from './pages/Sales';
import Expositor from './pages/Expositors';
import Speaker from './pages/Speakers';
import Students from './pages/Students';
import Program from './pages/Programs';
import Courses from './pages/Courses';
import ExpositorVote from './pages/ExpositorVotes';
import SponsorsAdmin from './pages/Sponsors';
import Reporter from './pages/Reporter';
import { Link, useParams } from 'react-router-dom';
import { authTitle } from '../../config';
import ProductsAdmin from './pages/Products';
import Certificate from './pages/Certificates';
import { id } from 'date-fns/locale';
import ImageGallery from './pages/ImageGallery';
import ExpositorsList from './pages/ExpositorList';
import VideoGallery from './pages/VideoGallery';
import PhotoGallery from './pages/PhotoGallery';
import UpdateData from './pages/UpdateData';
import Evento from './pages/Events';
import Slides from './pages/LessonSlides';
import Global from './pages/Global';
import Project from './pages/Platforms';
import fi from 'date-fns/esm/locale/fi/index.js';
import { usePages } from '../../hooks/Pages';
import LeadCapturePage from './pages/LeadCapturePage';
import FileGallerys from './pages/FileGallery';
import Resumo from './pages/Resumo';
import SpeakerPosition from './pages/SpeakersPosition';
import Exam from './pages/Exam';
import SatisfactionSurvey from './pages/SatisfactionSurvey';
import Podcast from './pages/Podcast';
import CientificLibrary from './pages/CientificLibrary';
import CientificLibraryAuthor from './pages/CientificLibraryAuthor';
import CientificLibraryInteraction from './pages/CientificLibraryInteraction';
import EventsPagesAdmin from './pages/EventsPages';
import CertificatesUser from './pages/CertificatesUsers';
import Testimonial from './pages/Testimonial';
import Scheduler from './pages/Scheduler';
import ReporterCSAT from './pages/ReportCSAT';
import ReporterCientificLibrary from './pages/ReportCientificLibrary';
import ReporterSubscriptions from './pages/ReportSubscriptions';

import ReporterRecorrence from './pages/ReportRecorrence';
import ReporterParticipants from './pages/ReportParticipants';
import { getMonth, getYear } from 'date-fns';
import SpeakerTerm from './pages/SpeakersTerm';
import SpeakerTermResponse from './pages/SpeakersTermResponse';

import PodcastLibrary from './pages/PodcastLibrary';
import PodcastLibraryAuthor from './pages/PodcastLibraryAuthor';
import CertificatesUsers from './pages/CertificatesUsers';
import ReporterPodcastLibrary from './pages/ReportPodcastLibrary';
import CourseProducts from './pages/CourseProduct';
import CourseProductsSubscriptions from './pages/CourseProductSubscription';
import CientificLibraryCategory from './pages/CientificLibraryCategory';
import ContentSubscription from './pages/ContentSubscription';

interface IReport {
  id: number,
  client_id: number,
  room_id: number,
  name: string,
}

interface IOnline {
  title: string;
  room_id: number;
  count: number;
}

interface IParams {
  tab: string;
  projectHash: string;
  eventHash: string;
}

const SidebarComponent = ({ validSearch, projectSearch, eventSearch, active = true, }) => {

  const { user } = useAuth();
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [events, setEvents] = useState<Array<Record<string, any>>>([]);
  const [sidebarActive, setSidebarActive] = useState(true);
  const { projectHash, eventHash, tab } = useParams<IParams>();

  const [currentProject, setCurrentProject] = useState('');
  const [currentEvent, setCurrentEvent] = useState('');




  useEffect(() => {
    setSidebarActive(active);
  }, [active]);

  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {
      setProjects(response?.data.rows);
    }
  }

  const loadEvents = async () => {
    const response = await api.get(`/events`, { params: { limitItems: 1000 } });
    if (response?.data) {
      setEvents(response?.data.rows);
    }
  }

  useEffect(() => {
    loadProjects();
    loadEvents();





  }, [])

  useEffect(() => {

    setCurrentEvent(eventHash || '');
    setCurrentProject(projectHash || '')
  }, [projectHash, eventHash])

  const renderValidSearch = () => {
    const items: Array<JSX.Element> = [];
    Object.keys(validSearch).map(key => {
      const { title, icon } = validSearch[key];
      items.push(<Link to={`/manager/${key}`}> {icon()} <p>{title}</p></Link>);

    })
    return items;

  }

  const renderValidProjectSearch = () => {
    const items: Array<JSX.Element> = [];
    const project = projects.find(p => p.url === currentProject);
    if (project) {
      Object.keys(projectSearch).map(key => {
        const { title, icon } = projectSearch[key];


        items.push(<Link to={`/manager/${key}/${project.url}`}> {icon()} <p>{title}</p></Link>);

      })

      const links: Array<JSX.Element> = [];
      events.map(event => {
        if (event.project_id === currentProject) {
          links.push(<Link key={`event-key-${event.url}`} to={`/manager/${Object.keys(eventSearch)[0]}/${currentProject}/${event.url}`}> <FaCalendar /> <p>{event.title}</p></Link>);
        }
      })


      return <>
        <h2 className='sidebar-title'>{project.title}</h2>
        {items}
        <h2 className='sidebar-subtitle'>Eventos</h2>
        {links}

      </>;
    }
    return <></>;
  }

  const renderValidEventSearch = () => {
    const items: Array<JSX.Element> = [];
    const eventInfo = events.find(p => p.url === currentEvent);

    if (eventInfo) {
      Object.keys(eventSearch).map(key => {

        const { title, icon } = eventSearch[key];


        items.push(<Link to={`/manager/${key}/${currentProject}/${eventInfo.url}`}> {icon()} <p>{title}</p></Link>);

      })
      return <>
        <h2 className='sidebar-title'>{eventInfo.title}</h2>
        {items}
      </>;
    }
    return <></>;
  }


  return <Sidebar active={sidebarActive}>
    <div className="menu" onClick={() => setSidebarActive(!sidebarActive)}><FiMenu size="30" /></div>
    <img src="/assets/logo.png" style={{ width: '90%', maxWidth: '150px', margin: '10px auto' }} />
    <div style={{ background: 'rgb(0,250,50)', padding: '5px 10px', borderRadius: '5px', margin: '10px auto', color: 'rgb(0,100,0)' }}>
      {user.name}
    </div>
    <div style={{ background: 'rgb(0,250,50)', padding: '5px 10px', borderRadius: '5px', margin: '10px auto', color: 'rgb(0,100,0)' }}>
      {authTitle}
    </div>

    {currentProject === '' &&
      <>
        {renderValidSearch()}
        <h2 className='sidebar-title'>Projetos</h2>
        {projects.map(project => <Link to={`/manager/home/${project.url}`} > <FaProjectDiagram /> <p>{project.title}</p></Link>)}
      </>

    }

    {currentProject !== '' && currentEvent === '' &&
      <>
        <Link to="/manager" onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Voltar</button></Link>
        <Link to={`/app/${currentProject}`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Home do projeto</button></Link>
        <Link to={`/app/${currentProject}/dashboard`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Palestras do projeto</button></Link>
        {renderValidProjectSearch()}
      </>
    }


    {currentProject !== '' && currentEvent !== '' && <>
      <Link to={`/manager/${Object.keys(projectSearch)[0]}/${projectHash}`}><button className='sidebarPrevButton'>Voltar</button></Link>
      <Link to={`/app/${currentProject}`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Home do projeto</button></Link>
      <Link to={`/app/${currentProject}/dashboard`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Palestras do projeto</button></Link>

      {renderValidEventSearch()}
    </>
    }

  </Sidebar>
}


const Dashboard: React.FC = () => {
  const endpoint = '/students';


  const { tab = 'projects', projectHash = '', eventHash = '' } = useParams<IParams>();
  const { token, user } = useAuth();

  const [online, setOnline] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const [report, setReport] = useState<Array<IReport>>([]);
  const [search, setSearch] = useState(tab);
  const [rooms, setRooms] = useState<Array<IOnline>>([]);
  const { prepareMenu } = usePages();

  useEffect(() => {


    prepareMenu({ projectHash, active_pages: [] });
  }, [])


  let validSearch: Record<string, any> = {
    student: { icon: () => <FaUser />, title: 'Cadastros', render: () => <Students /> },


  };

  let projectSearch: Record<string, any> = {};
  let eventSearch: Record<string, any> = {};

  if (user.profile === 1) {
    validSearch = {
      ...validSearch,
      global: { icon: () => <FaBook />, title: 'Configurações de Capa', render: () => <Global /> },
      projects: { icon: () => <FaProjectDiagram />, title: 'Projetos', render: () => <Project /> },
      leadpage: { icon: () => <FaNewspaper />, title: 'Leads', render: () => <LeadCapturePage /> },
      /*
           expositorslist: { icon: () => <FaGifts />, title: 'Cortesias', render: () => <ExpositorsList /> },
           reporter: { icon: () => <FaChartBar />, title: 'Relatórios', render: () => <Reporter />, closeSidebar: true },
           sale: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
     
           updateData: { icon: () => <FaVideo />, title: 'Update nos Dados', render: () => <UpdateData /> },
           certificateAdmin: { icon: () => <FaCertificate />, title: 'Certificados Modelos', render: () => <Certificate /> },
     
           certificateUser: { icon: () => <FaCertificate />, title: 'Certificados Emitidos', render: () => <CertificatesUser /> },
         */

      // cientificLibraryInteraction: { icon: () => <FiMessageSquare />, title: 'Interações da Biblioteca', render: () => <CientificLibraryInteraction /> },


      //  podcastGalery: { icon: () => <FaFileAudio />, title: 'Galeria de Podcasts', render: () => <PodcastLibrary /> },
      //  podcastLibraryAuthor: { icon: () => <FaPenSquare />, title: 'Autores da Biblioteca', render: () => <PodcastLibraryAuthor /> },



      //  testimonial: { icon: () => <FaComment />, title: 'Depoimentos', render: () => <Testimonial /> },
    }

    projectSearch = {
      ...projectSearch,
      cientificLibrary: { icon: () => <FaBook />, title: 'Conteúdos', render: () => <CientificLibrary /> },
      cientificLibraryAuthor: { icon: () => <FaPenSquare />, title: 'Autores', render: () => <CientificLibraryAuthor /> },
      cientificLibraryCategory: { icon: () => <FaPenSquare />, title: 'Categorias', render: () => <CientificLibraryCategory /> },
      reportCientificLibrary: { icon: () => <FaChartArea />, title: 'Relatórios', render: () => <ReporterCientificLibrary /> },
      contentSubscription: { icon: () => <FaUtensilSpoon />, title: 'Receitas enviadas pelo público', render: () => <ContentSubscription /> },
      events: { icon: () => <FaCalendar />, title: 'Eventos', render: () => <Evento /> },


      /*    home: { icon: () => <FaCalendar />, title: 'Eventos', render: () => <Evento /> },
        resume: { icon: () => <FaChartArea />, title: 'Resumo', render: () => <Resumo /> },
        filegallery: { icon: () => <FaFilePdf />, title: 'Galeria de Arquivos', render: () => <FileGallerys /> },
        podcast: { icon: () => <FaPodcast />, title: 'Podcasts', render: () => <Podcast /> },
        sponsor: { icon: () => <FaStar />, title: 'Patrocinadores', render: () => <SponsorsAdmin /> },
         course: { icon: () => <FaBoxes />, title: 'Cursos', render: () => <Courses /> },
        sale: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
  
        eventsPages: { icon: () => <FiLayout />, title: 'Páginas Avulsas', render: () => <EventsPagesAdmin /> },
        slides: { icon: () => <FaSlideshare />, title: 'Slides', render: () => <Slides /> },
        speaker: { icon: () => <FaMicrophone />, title: 'Palestrantes', render: () => <Speaker /> },
        lesson2: { icon: () => <FaBox />, title: 'Palestras (Não criar)', render: () => <Lessons /> },
        satisfaction_project: { icon: () => <FaQuestion />, title: 'Pesquisa de Satisfação', render: () => <SatisfactionSurvey /> },
  */
    }

    if (user.email === 'guilhermethiesen@gmail.com') {
      projectSearch.scheduler = { icon: () => <FaCalendarDay />, title: 'Casos Clínicos', render: () => <Scheduler /> };
    }

    eventSearch = {
      ...eventSearch,
      product: { icon: () => <FaTicketAlt />, title: 'Produtos', render: () => <ProductsAdmin /> },

      /*     home2: { icon: () => <FaBox />, title: 'Palestras', render: () => <Lessons /> },
          resume2: { icon: () => <FaChartArea />, title: 'Resumo', render: () => <Resumo /> },
          program: { icon: () => <FaCalendar />, title: 'Programação', render: () => <Program /> },
          sponsor: { icon: () => <FaStar />, title: 'Patrocinadores', render: () => <SponsorsAdmin /> },
          slides: { icon: () => <FaSlideshare />, title: 'Slides', render: () => <Slides /> },
          imagegallery: { icon: () => <FaRegSmileBeam />, title: 'Mini Expositor', render: () => <ImageGallery /> },
          photogallery: { icon: () => <FaImages />, title: 'Galerias de Fotos', render: () => <PhotoGallery /> },
          videogallery: { icon: () => <FaVideo />, title: 'Galerias de Vídeos', render: () => <VideoGallery /> },
          speakersPosition: { icon: () => <FaSpeakerDeck />, title: 'Blocos de Palestrantes', render: () => <SpeakerPosition /> },
          expositor: { icon: () => <FaBuilding />, title: 'Expositores', render: () => <Expositor /> },
          course: { icon: () => <FaBoxes />, title: 'Cursos', render: () => <Courses /> },
          saleEvent: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
          certificate: { icon: () => <FaCertificate />, title: 'Certificados', render: () => <Certificate /> },
          certificate_user: { icon: () => <FaCertificate />, title: 'Certificados gerados', render: () => <CertificatesUsers /> },
          satisfaction: { icon: () => <FaQuestion />, title: 'Pesquisa de Satisfação', render: () => <SatisfactionSurvey /> },
          exam: { icon: () => <FaReceipt />, title: 'Exame', render: () => <Exam /> },
          speakerTerm: { icon: () => <FaFileContract />, title: 'Termo de Palestrante', render: () => <SpeakerTerm /> },
          speakerTermResponse: { icon: () => <FaFileContract />, title: 'Termos assinados', render: () => <SpeakerTermResponse /> },
    
          courseProduct: { icon: () => <FaSubscript />, title: 'Atividades selecionáveis', render: () => <CourseProducts /> },
          courseProductSubscriptions: { icon: () => <FaPlusCircle />, title: 'Inscrições em atividades', render: () => <CourseProductsSubscriptions /> },
    */

    }
  }




  useEffect(() => {

    if (tab) {

      setSearch(tab);

    }



  }, [tab, projectHash, eventHash])



  if (loading) {
    return <Container>Você foi removido</Container>;
  }

  const atualizar = async () => {
    await api.post('/update-participation');
    console.log('Finalizado')
    atualizar();

  }



  return (<>

    <Container>

      <SidebarComponent active={validSearch?.[tab]?.closeSidebar || window.innerWidth <= 800 ? false : true} projectSearch={projectSearch} validSearch={validSearch} eventSearch={eventSearch} />
      <div style={{ width: '100%' }}>
        <CardContainer>



          <CounterCardRepeat

            endpoint="/report/onlinePlayers"
            title="Usuários Online"
            Icon={FiUserPlus}

          />
          <CounterCard
            endpoint={`/report/users/${projectHash}`}
            title="Usuários Cadastrados"
            Icon={FiUsers}
            link="/manager/student"
          />
          {user.profile === 1 && <>
            <CounterCard
              endpoint={`/report/sales_paid/${projectHash}`}
              title="Inscrições em projetos"
              Icon={FiCreditCard}
              link="/manager/sale"
            />
          </>}



        </CardContainer>


        {!projectHash && validSearch[search] ? validSearch[search].render() : <></>}
        {projectHash && projectSearch[search] ? projectSearch[search].render() : <></>}
        {eventHash && eventSearch[search] ? eventSearch[search].render() : <></>}
      </div>


    </Container></>
  );
};

export default Dashboard;
