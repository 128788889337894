import React from 'react';
import { useModal } from "../../../hooks/Modal";
import FormContainer from '../FormContainer';
import { AnimatedDiv, Container, Content } from '../styles';

export default function useReceitas() {


  const { addModal } = useModal();



  const sendLead = () => {

    addModal({
      title: '', content: <Container key="receita-popup">
        <Content>
          <AnimatedDiv>


            <FormContainer />
          </AnimatedDiv>
        </Content>
      </Container>, theme: 'whiteModalMedium'
    })

  }

  return { sendLead };


}