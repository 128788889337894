import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';
import { ScrollProvider } from './Scroll';
import { SocketProvider } from './Socket';
import { PagesProvider } from './Pages';
import { InteractionProvider } from './Interactions';
import { LanguageProvider } from './Language';
import { ConfigurationProvider } from './Configuration';
import { LoadingProvider } from './LoadingHook';
const AppProvider: React.FC = ({ children }) => {
  return (
    <LoadingProvider>
      <AuthProvider>
        <ConfigurationProvider>
          <LanguageProvider>
            <PagesProvider>
              <SocketProvider>
                <ToastProvider>
                  <ModalProvider>
                    <InteractionProvider>

                      <SearchProvider>
                        <ScrollProvider>{children}</ScrollProvider>
                      </SearchProvider>

                    </InteractionProvider>
                  </ModalProvider>
                </ToastProvider>
              </SocketProvider>
            </PagesProvider>
          </LanguageProvider>
        </ConfigurationProvider>
      </AuthProvider>
    </LoadingProvider>
  );
};

export default AppProvider;
