import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import downloadit from '../../../../utils/download';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const ContentSubscription: React.FC = () => {
  const endpoint = '/content-submission';
  const title = 'Receitas da Galera';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },


    status: { ref: 'status', column: 'status', label: 'Status' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    name: { ref: 'name', column: 'name', label: 'Enviado por' },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'textareaInput', type: 'text', name: 'description', label: 'Descritivo' },

    category: { model: 'input', type: 'text', name: 'category', label: 'Região' },
    name: { model: 'input', type: 'text', name: 'name', label: 'Enviado por' },
    email: { model: 'input', type: 'text', name: 'email', label: 'E-mail' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },

  };






  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Capa',
    },


  };


  const formValidation: Record<string, ValidationSchema> = {

  };





  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ContentSubscription;
