import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import exportSingleUserInfos from './modules/ExportSingleUserInfos';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/students';
  const title = 'Participantes';
  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento' },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF',
    },
    cpf_familiar: {
      ref: 'cpf_familiar',
      column: 'cpf_familiar',
      label: 'Categoria',
    },
    position: {
      ref: 'position',
      column: 'position',
      label: 'Especialidade',
    },
    updated_information: {
      ref: 'updated_information',
      column: 'updated_information',
      label: 'Atualizado?',
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },

    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    zipcode: { model: 'input', type: 'text', name: 'zipcode', label: 'CEP' },
    instituicao: {
      model: 'input',
      type: 'text',
      name: 'instituicao',
      label: 'Instituição',
    },

    password_hash: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },
    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '2',
      options: [
        { label: 'Administrador', value: 1 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },
      ],
    },
    updated_information: {
      model: 'selectSimple',
      name: 'updated_information',
      label: 'Atualizado?',
      defaultValue: 'no',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ],
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        exportSingleUserInfos({
          lineData,

        }),

    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
